import React, { useState, useEffect } from "react";
import { Form, Input, Button, message, Checkbox, Radio } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import "./index.less";
import { userLogin } from "@/api/project";
const Index = () => {
  const [allData, setAllData] = useState([]);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  useEffect(() => {
    // sessionStorage.clear();
  }, []);
  const loginBtn = () => {
    form.validateFields().then((loginData) => {
      const formData = new FormData();
      formData.append("username", loginData.username);
      formData.append("password", loginData.password);
      userLogin(formData)
        .then(async (res) => {
          if (res.success) {
            message.success("登录成功！");
            sessionStorage.setItem("token", res.data.token);
            navigate("/home/user_list");
          }
        })
        .catch((err) => {
          console.log(err);
          message.error("登录失败，请检查用户名或密码是否正确");
        });
    });
  };
  return (
    <>
      <div className="loginPageContant">
        <div className="headerTitle">
          <span>Lexi AI 新闻管理后台</span>
        </div>
        <div className="loginPagebody">
          <div className="loginPageBodyHeader">
            <p>登录</p>
          </div>
          <div className="loginPagebodyContent">
            <Form layout={"vertical"} size={"large"} form={form}>
              <Form.Item
                label="用户名"
                name="username"
                rules={[
                  {
                    required: true,
                    message: "请输入您的名称",
                  },
                ]}
              >
                <Input placeholder="请输入用户名" />
              </Form.Item>
              <Form.Item
                label="密码"
                name="password"
                rules={[
                  {
                    required: true,
                    message: "请输入您的密码",
                  },
                ]}
              >
                <Input.Password placeholder="请输入密码" />
              </Form.Item>
            </Form>
            {/* <div className="loginPagebodyContentEnd">
              <a href="#">忘记密码 ？</a>
            </div> */}
          </div>
          <div className="loginPagebodyFooter">
            {/* <Radio checked={true}>
              我已阅读并同意 《<a href="#">服务协议</a>》
            </Radio> */}
            <Button
              type="primary"
              htmlType="submit"
              size="large"
              onClick={loginBtn}
            >
              登录
            </Button>
          </div>
          {/* <div className="loginPagebodyEnd">
            <a href="#">没有账号去注册</a>
          </div> */}
        </div>
        <div className="bottomText">
          <p>Copyright © Lexi AI All Rights Reserved</p>
          {/* <p>© 爱人智能 版权所有</p> */}
        </div>
      </div>
    </>
  );
};

export default Index;

/* 
接口 api
*/
import axios from "./axios";
const baseUrl = "/api";
const baseUrlV1 = "/v1";

//用户登录
export const userLogin = (params) => {
  return axios.post(`${baseUrl}/token`, params);
};
// 获取用户列表记录
export const getAdminUsers = (params) => {
  return axios.get(`${baseUrlV1}/admin/users`, params);
};
// 获取语言配置
export const languageSetting = (params) => {
  return axios.get(`${baseUrl}/news/languageSetting`, params);
};
// 获取评论员提示词
export const getCommentators = (params) => {
  return axios.get(`${baseUrlV1}/admin/commentators`, params);
};
//修改评论员提示词
export const updateCommentators = (commentatorId, params) => {
  return axios.patch(`${baseUrlV1}/admin/commentator/${commentatorId}`, params);
};
// 获取关联分类
export const getChannels = (params) => {
  return axios.get(`${baseUrlV1}/admin/channels`, params);
};
// 新增关联分类
export const addChannels = (params) => {
  return axios.post(`${baseUrlV1}/admin/channels`, params);
};
// 编辑关联分类
export const updateChannels = (params) => {
  return axios.patch(`${baseUrlV1}/admin/channels`, params);
};
// 获取分类提示词
export const getChannelPrompts = (params) => {
  return axios.get(`${baseUrlV1}/admin/channelPrompts`, params);
};
// 新增分类提示词
export const addChannelPrompts = (params) => {
  return axios.post(`${baseUrlV1}/admin/channelPrompts`, params);
};
// 编辑分类提示词
export const updateChannelPrompts = (params) => {
  return axios.patch(`${baseUrlV1}/admin/channelPrompts`, params);
};
// 文章搜索
export const articleSearch = (params) => {
  return axios.post(`${baseUrlV1}/admin/articleSearch`, params);
};
// 文章批量删除
export const deleteArticles = (params) => {
  return axios.delete(`${baseUrlV1}/admin/articleSearch`, params);
};
// 编辑文章
export const updateArticles = (params) => {
  return axios.patch(`${baseUrlV1}/admin/articleSearch`, params);
};
// 获取运营活动
export const getNewsOperateActivity = (params) => {
  return axios.get(`${baseUrlV1}/admin/newsOperateActivity`, params);
};
// 添加运营活动
export const addNewsOperateActivity = (params) => {
  return axios.post(`${baseUrlV1}/admin/newsOperateActivity`, params);
};
// 编辑运营活动
export const updateNewsOperateActivity = (params) => {
  return axios.patch(`${baseUrlV1}/admin/newsOperateActivity`, params);
};
// 导入多语言文件
export const addLanguageSetting = (params) => {
  return axios.post(`${baseUrlV1}/admin/addLanguageSetting`, params);
};
// 获取更新记录
export const getUpdateRecord = (params) => {
  return axios.get(`${baseUrlV1}/admin/updateRecord`, params);
};
// 获取模型
export const getModels = (params) => {
  return axios.get(`${baseUrlV1}/admin/models`, params);
};
// 获取分类提示词
export const getChannelConfig = (params) => {
  return axios.get(`${baseUrlV1}/admin/channelConfig`, params);
};
// 更新新闻
export const byApiGetArticles = (params) => {
  return axios.post(`${baseUrlV1}/admin/by-api-get-articles`, params);
};

import React from "react";
import "./index.less";
import img from "../../assets/imgs/404.png";

function NotFound() {
  return (
    <div className="not-found">
      <img src={img} alt="404" />
      <div className="text">Not Found</div>
    </div>
  );
}

export default NotFound;

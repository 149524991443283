import React, { lazy, Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Spin } from "antd";
// 用户管理
const UserList = lazy(() => import("../pages/user-m/user-list"));
// 评论员管理
const CommentatorWords = lazy(() =>
  import("../pages/commentator-m/commentator-words")
);
// 分类配置
const ClassificationList = lazy(() =>
  import("../pages/classification-config/classification-list")
);
const ClassificationWords = lazy(() =>
  import("../pages/classification-config/classification-words")
);
// 运营
const OperationalAct = lazy(() => import("../pages/operation/operational-act"));
// APP配置
const MultilingualM = lazy(() => import("../pages/app-config/multilingual-m"));
// 内容管理
const ArticleSearch = lazy(() => import("../pages/content-m/article-search"));

const routeList = [
  {
    key: "user_list",
    name: "用户列表",
    path: "/user_list",
    component: UserList,
  },
  {
    key: "commentator_words",
    name: "评论员提示词",
    path: "/commentator_words",
    component: CommentatorWords,
  },
  {
    key: "classification_list",
    name: "分类列表",
    path: "/classification_list",
    component: ClassificationList,
  },
  // {
  //   key: "classification_words",
  //   name: "分类提示词",
  //   path: "/classification_words",
  //   component: ClassificationWords,
  // },
  {
    key: "operational_act",
    name: "运营活动",
    path: "/operational_act",
    component: OperationalAct,
  },
  {
    key: "multilingual_m",
    name: "多语言管理",
    path: "/multilingual_m",
    component: MultilingualM,
  },
  {
    key: "article_search",
    name: "文章搜索",
    path: "/article_search",
    component: ArticleSearch,
  },
];
function homeRoutes() {
  return (
    <div>
      <Routes>
        <Route
          index
          element={
            <Suspense fallback={<Spin tip="Loading" fullscreen></Spin>}>
              <UserList />
            </Suspense>
          }
        ></Route>
        {routeList.map((item, index) => {
          return (
            <Route
              key={index}
              path={item.path}
              element={
                <Suspense fallback={<Spin tip="Loading" fullscreen></Spin>}>
                  <item.component />
                </Suspense>
              }
            ></Route>
          );
        })}
      </Routes>
    </div>
  );
}
export default homeRoutes;

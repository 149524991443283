import React, { useEffect, useMemo, useState } from "react";
import {
  ProjectOutlined,
  MenuUnfoldOutlined,
  FormOutlined,
  MenuFoldOutlined,
  UploadOutlined,
  UserOutlined,
  BarChartOutlined,
  UnorderedListOutlined,
  PartitionOutlined,
  AuditOutlined,
  HddOutlined,
  AppstoreOutlined,
} from "@ant-design/icons";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Layout,
  Menu,
  theme,
  Button,
  message,
  Collapse,
  Breadcrumb,
  Avatar,
  Popover,
  Modal,
} from "antd";
import "./style.less";
import Routes from "../../route/homeRoutes";
const { Header, Content, Sider } = Layout;
import logoImg from "@/assets/imgs/logo.png";
import logo1Img from "@/assets/imgs/logo1.jpg";

const menuItems = [
  {
    key: "1",
    icon: <UserOutlined />,
    label: "用户管理",
    children: [
      {
        key: "user_list",
        label: "用户列表",
      },
    ],
  },
  {
    key: "2",
    icon: <AuditOutlined />,
    label: "评论员管理",
    children: [
      {
        key: "commentator_words",
        label: "评论员提示词",
      },
    ],
  },
  {
    key: "3",
    icon: <HddOutlined />,
    label: "分类配置",
    children: [
      {
        key: "classification_list",
        label: "分类列表",
      },
    ],
  },
  {
    key: "4",
    icon: <PartitionOutlined />,
    label: "运营",
    children: [
      {
        key: "operational_act",
        label: "运营活动",
      },
    ],
  },
  {
    key: "5",
    icon: <AppstoreOutlined />,
    label: "APP配置",
    children: [
      {
        key: "multilingual_m",
        label: "多语言管理",
      },
    ],
  },
  {
    key: "6",
    icon: <MenuFoldOutlined />,
    label: "内容管理",
    children: [
      {
        key: "article_search",
        label: "文章搜索",
      },
    ],
  },
  // {
  //   key: "2",
  //   icon: <BarChartOutlined />,
  //   label: "数据统计",
  //   children: [
  //     {
  //       key: "user_data",
  //       label: "用户数据",
  //     },
  //     {
  //       key: "subscription_data",
  //       label: "订阅数据",
  //     },
  //     {
  //       key: "translate_data",
  //       label: "翻译数据",
  //     },
  //     {
  //       key: "tool_data",
  //       label: "工具数据",
  //     },
  //     {
  //       key: "token_data",
  //       label: "token数据",
  //     },
  //     {
  //       key: "token_data_overview",
  //       label: "token数据总览",
  //     },
  //     {
  //       key: "feedback_data",
  //       label: "反馈数据",
  //     },
  //   ],
  // },
  // {
  //   key: "3",
  //   icon: <HddOutlined />,
  //   label: "公共模块管理",
  //   children: [
  //     {
  //       key: "ver_update",
  //       label: "版本更新配置",
  //     },
  //     {
  //       key: "sub_price",
  //       label: "订阅价格设置",
  //     },
  //     {
  //       key: "sub_benefits",
  //       label: "订阅权益设置",
  //     },
  //     {
  //       key: "tool_config",
  //       label: "工具配置",
  //     },
  //     {
  //       key: "multilingual",
  //       label: "多语言设置",
  //     },
  //     {
  //       key: "server_multilingual",
  //       label: "服务器多语言设置",
  //     },
  //     {
  //       key: "historical_ver",
  //       label: "历史版本",
  //     },
  //     {
  //       key: "service_diversion",
  //       label: "服务分流配置",
  //     },
  //     {
  //       key: "system_param",
  //       label: "系统参数配置",
  //     },
  //   ],
  // },
  // {
  //   key: "4",
  //   icon: <PartitionOutlined />,
  //   label: "系统管理",
  //   children: [
  //     {
  //       key: "operation_log",
  //       label: "操作日志",
  //     },
  //     {
  //       key: "backend_account",
  //       label: "后台账号管理",
  //     },
  //     {
  //       key: "backend_role",
  //       label: "后台角色管理",
  //     },
  //     {
  //       key: "backend_menu",
  //       label: "后台菜单管理",
  //     },
  //   ],
  // },
];
const breadcrumbData = {
  user_list: [{ title: "用户管理" }, { title: "用户列表" }],
  commentator_words: [{ title: "评论员管理" }, { title: "评论员提示词" }],
  classification_list: [{ title: "分类配置" }, { title: "分类列表" }],
  // classification_words: [{ title: "分类配置" }, { title: "分类提示词" }],
  operational_act: [{ title: "运营" }, { title: "运营活动" }],
  multilingual_m: [{ title: "APP配置" }, { title: "多语言管理" }],
  article_search: [{ title: "内容管理" }, { title: "文章搜索" }],
};
const Home = () => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const [selectMenu, setSelectMenu] = useState("user_list");
  const [collapsed, setCollapsed] = useState(false);
  const [open, setOpen] = useState(false);
  const [openPopover, setOpenPopover] = useState(false);
  const [breadcrumbArr, setBreadcrumbArr] = useState([
    { title: "用户管理" },
    { title: "用户列表" },
  ]);
  const navigate = useNavigate();
  const location = useLocation();

  const showModal = () => {
    setOpen(true);
  };
  const hideModal = () => {
    setOpen(false);
  };
  const exitLogin = () => {
    setOpen(false);
    navigate("/login");
    message.success("已退出登录");
    sessionStorage.clear();
  };
  useEffect(() => {
    const tokenStr = sessionStorage.getItem("token");
    if (tokenStr == null || tokenStr == "") {
      sessionStorage.clear();
      navigate("/login");
      return;
    }
    const pathUrl = location.pathname;
    if (pathUrl.split("/").length < 4) {
      if (
        pathUrl === "/" ||
        pathUrl === "/home" ||
        pathUrl === "/home/user_list"
      ) {
        setSelectMenu("user_list");
        navigate("/home/user_list");
      } else {
        setSelectMenu(pathUrl.split("/")[2]);
        setBreadcrumbArr(breadcrumbData[pathUrl.split("/").at(-1)]);
      }
    } else {
      setSelectMenu(`${pathUrl.split("/")[2]}/${pathUrl.split("/")[3]}`);
    }
  }, []);
  const handleClick = (e) => {
    //   user_list: [{ title: "用户管理" }, { title: "用户列表" }],
    // commentator_words: [{ title: "评论员管理" }, { title: e.domEvent.target.innerText }],
    // classification_list: [{ title: "分类配置" }, { title: e.domEvent.target.innerText }],
    // classification_words: [{ title: "分类配置" }, { title: e.domEvent.target.innerText }],
    // operational_act: [{ title: "运营" }, { title: e.domEvent.target.innerText }],
    // multilingual_m: [{ title: "APP配置" }, { title: e.domEvent.target.innerText }],
    // article_search: [{ title: "内容管理" }, { title: e.domEvent.target.innerText }],
    setSelectMenu(e.key);
    navigate(`/home/${e.key}`);
    // console.log(e, e.domEvent.target.innerText);
    if (e.keyPath[1] === "1") {
      setBreadcrumbArr([
        { title: "用户管理" },
        { title: e.domEvent.target.innerText },
      ]);
    } else if (e.keyPath[1] === "2") {
      setBreadcrumbArr([
        { title: "评论员管理" },
        { title: e.domEvent.target.innerText },
      ]);
    } else if (e.keyPath[1] === "3") {
      setBreadcrumbArr([
        { title: "分类配置" },
        { title: e.domEvent.target.innerText },
      ]);
    } else if (e.keyPath[1] === "4") {
      setBreadcrumbArr([
        { title: "运营" },
        { title: e.domEvent.target.innerText },
      ]);
    } else if (e.keyPath[1] === "5") {
      setBreadcrumbArr([
        { title: "APP配置" },
        { title: e.domEvent.target.innerText },
      ]);
    } else {
      setBreadcrumbArr([
        { title: "内容管理" },
        { title: e.domEvent.target.innerText },
      ]);
    }
  };
  const popoverContent = () => (
    <div>
      <p
        style={{ cursor: "pointer" }}
        onClick={() => {
          showModal();
          setOpenPopover(false);
        }}
      >
        退出登录
      </p>
    </div>
  );
  const handleOpenChange = (newOpen) => {
    setOpenPopover(newOpen);
  };
  return (
    <>
      <Layout className="home-wrap">
        <Sider
          trigger={null}
          // collapsible
          collapsed={collapsed}
          style={{
            // background: colorBgContainer,
            overflow: "auto",
            height: "100vh",
            boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.15)",
          }}
        >
          <div className="sider-logo">
            <div className="logo-icon">
              <img src={collapsed ? logo1Img : logoImg} alt="" />
            </div>
          </div>
          <Menu
            theme="dark"
            mode="inline"
            defaultSelectedKeys={[selectMenu]}
            selectedKeys={[selectMenu]}
            items={menuItems}
            onClick={handleClick}
          />
          {/* <div
            style={{
              color: "#498EDA",
              position: "absolute",
              bottom: 30,
              right: 5,
              fontSize: 23,
            }}
          >
            {React.createElement(
              collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
              {
                className: "trigger",
                onClick: () => setCollapsed(!collapsed),
              }
            )}
          </div> */}
        </Sider>
        <Layout className="site-layout">
          <Header className="site-layout-background header-wrap">
            <div className="header-left-part">
              <Breadcrumb items={breadcrumbArr} />
            </div>
            <div className="header-right-part">
              <Popover
                placement="bottom"
                content={popoverContent}
                trigger="click"
                open={openPopover}
                onOpenChange={handleOpenChange}
              >
                <Avatar
                  className="avatar"
                  style={{
                    color: "#ffffff",
                    backgroundColor: "#498EDA",
                  }}
                  size="large"
                >
                  用户
                </Avatar>
              </Popover>
            </div>
          </Header>
          <Content
            className="site-layout-background"
            style={{
              margin: 16,
              padding: "24px 24px 0",
              minHeight: 280,
              borderRadius: 5,
            }}
          >
            <Routes />
          </Content>
        </Layout>
      </Layout>
      <Modal
        title="确认退出登录吗？"
        open={open}
        onOk={exitLogin}
        onCancel={hideModal}
      ></Modal>
    </>
  );
};
export default Home;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#components-layout-demo-custom-trigger .trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}
#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}
#components-layout-demo-custom-trigger .logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}
.site-layout .site-layout-background {
  background: #fff;
}
.sider-section {
  padding-bottom: 40px;
  position: relative;
  height: 100%;
}
.sider-logo-collapsed {
  width: 0px !important;
  opacity: 0;
}
.sider-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 85px;
}
.sider-logo .logo-icon img {
  width: 100%;
  height: 35px;
}
.logo-text {
  max-width: 130px;
  display: inline-block;
  word-wrap: break-word;
  text-align: center;
  font-size: 14px !important;
}
.home-wrap .header-wrap {
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
}
.home-wrap .header-left-part {
  margin-left: 20px;
  padding-top: 25px;
}
.home-wrap .header-right-part {
  margin-right: 30px;
}
.home-wrap .header-right-part .avatar {
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/pages/home/style.less"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,eAAA;EACA,iBAAA;EACA,eAAA;EACA,sBAAA;AACF;AAEA;EACE,cAAA;AAAF;AAGA;EACE,YAAA;EACA,YAAA;EACA,oCAAA;AADF;AAIA;EACE,gBAAA;AAFF;AAIA;EACE,oBAAA;EACA,kBAAA;EACA,YAAA;AAFF;AAIA;EACE,qBAAA;EACA,UAAA;AAFF;AAIA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,YAAA;AAFF;AAFA;EAOM,WAAA;EACA,YAAA;AAFN;AAMA;EACE,gBAAA;EACA,qBAAA;EACA,qBAAA;EACA,kBAAA;EACA,0BAAA;AAJF;AAMA;EAGI,aAAA;EACA,8BAAA;EACA,eAAA;AANJ;AACA;EASI,iBAAA;EACA,iBAAA;AAPJ;AAHA;EAaI,kBAAA;AAPJ;AANA;EAeM,eAAA;AANN","sourcesContent":["#components-layout-demo-custom-trigger .trigger {\n  padding: 0 24px;\n  font-size: 18px;\n  line-height: 64px;\n  cursor: pointer;\n  transition: color 0.3s;\n}\n\n#components-layout-demo-custom-trigger .trigger:hover {\n  color: #1890ff;\n}\n\n#components-layout-demo-custom-trigger .logo {\n  height: 32px;\n  margin: 16px;\n  background: rgba(255, 255, 255, 0.3);\n}\n\n.site-layout .site-layout-background {\n  background: #fff;\n}\n.sider-section {\n  padding-bottom: 40px;\n  position: relative;\n  height: 100%;\n}\n.sider-logo-collapsed {\n  width: 0px !important;\n  opacity: 0;\n}\n.sider-logo {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 85px;\n  .logo-icon {\n    img {\n      width: 100%;\n      height: 35px;\n    }\n  }\n}\n.logo-text {\n  max-width: 130px;\n  display: inline-block;\n  word-wrap: break-word;\n  text-align: center;\n  font-size: 14px !important;\n}\n.home-wrap {\n  // border: 1px solid red;\n  .header-wrap {\n    display: flex;\n    justify-content: space-between;\n    padding: 0 20px;\n  }\n  .header-left-part {\n    // border: 1px solid red;\n    margin-left: 20px;\n    padding-top: 25px;\n  }\n  .header-right-part {\n    margin-right: 30px;\n    .avatar {\n      cursor: pointer;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

/* 
axios 请求
*/
const axios = require("axios");
const handleAuth = (config) => {
  let token = sessionStorage.getItem("token") || "";
  config.headers["Admin-Token"] = token;
  return config;
};
const service = axios.create({
  baseURL: process.env.REACT_APP_URL,
});
service.interceptors.request.use(
  function (config) {
    config = handleAuth(config);
    return config;
  },
  function (err) {
    return Promise.reject(err);
  }
);
service.interceptors.response.use(
  function (response) {
    let res = response.data;
    if (res && res.reason === "USER_NOT_LOGIN") {
      sessionStorage.setItem("errorMessage", "登录超时");
      // message.error(res.message);
      // history.default.push('/login');
      // window.location.reload();
      return;
    }
    return response.data;
  },
  function (err) {
    return Promise.reject(err);
  }
);

function apiAxios(method, params, url, options) {
  let getParams =
    method === "get"
      ? {
          params: params,
          ...options,
        }
      : params;
  return new Promise((resolve, reject) => {
    service[method](url, getParams, options)
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
const ajax = {
  get: (url, params, options) => apiAxios("get", params, url, options),
  post: (url, params, options) => apiAxios("post", params, url, options),
  put: (url, params, options) => apiAxios("put", params, url, options),
  delete: (url, params, options) => apiAxios("delete", params, url, options),
  patch: (url, params, options) => apiAxios("patch", params, url, options),
};

export default ajax;
